// extracted by mini-css-extract-plugin
export var result = "_result-module--result--X0k1A";
export var result__wrap = "_result-module--result__wrap--WUCGC";
export var result__tab = "_result-module--result__tab--jbpX6";
export var result__tab__current = "_result-module--result__tab__current--qkTRX";
export var result__tab_grey = "_result-module--result__tab_grey--eTj8l";
export var result__tab_color = "_result-module--result__tab_color--kG41T";
export var result__triangle = "_result-module--result__triangle--yhpEO";
export var result__year = "_result-module--result__year--ZB19s";
export var result__year__current = "_result-module--result__year__current--QgmeF";
export var result__box = "_result-module--result__box--nLBZk";
export var result__winner = "_result-module--result__winner--vttlm";
export var result__name = "_result-module--result__name--CC+1e";
export var result__list = "_result-module--result__list--VxhL6";
export var result__date = "_result-module--result__date--WJfTX";
export var result__match_title = "_result-module--result__match_title--cE4BQ";
export var result__number_wrap = "_result-module--result__number_wrap--rygP0";
export var result__number = "_result-module--result__number--HD7GV";
export var result__vs = "_result-module--result__vs--YR8hr";
export var result__btn = "_result-module--result__btn--TBxTK";
export var result__not_content = "_result-module--result__not_content--kMa5V";
export var result__artist = "_result-module--result__artist--oH7q8";