import * as React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';
import * as Styles from '../styles/pages/_result.module.scss';
import * as CommonStyles from '../styles/global/_common.module.scss';
import { dayjs } from '../lib/dayjs';
import Pagination from '../components/pagination';
import { BackGround } from '../components/background';
import { BreadCrumb } from '../components/breadcrumb';
import classNames from 'classnames';
import {Link, useIntl} from "gatsby-plugin-react-intl"
import { contentPathHelper } from '../utils/contentPathHelper';
import defaultUserIcon from '../images/icon_user_default.jpg'

const Result = ({ pageContext, location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  const { nodes, prevPagePath, nextPagePath, pagination, currentPageNum, totalPageNum, categories } = pageContext;

  /** 2ページ以上ページネーションが存在すれば true */
  const isPageNation = React.useMemo(() => totalPageNum > 1, [totalPageNum]);

  // 年数一覧
  const years = categories.map((category) => category.slug).sort((a, b) => b - a);

  // 現在表示されている試合結果一覧の年を取得
  const currentYear = location.pathname === '/match/' || location.pathname === '/match' || location.pathname === `/match/year/${years[0]}` ? years[0] : location.pathname.slice(-5).replace(/[^0-9]/g, '');

  const matchComponent = (node) => (
    <div key={node.matchId}>
      <p className={Styles.result__name}>{node.title}</p>
        <div className={Styles.result__list}>
          <p className={Styles.result__date}>{dayjs(node.date).format('MM月DD日(ddd)')}</p>
          {node.nodes.map(node => (
            <div className={Styles.result__box}>
              {/*青アーティストアイコン*/}
              <div className={classNames(node.winner[0] === '青' && Styles.result__winner)}>
                <div className={Styles.result__artist}>
                  {node.blue.length > 0 && node.blue.map((b, i) => (
                    <div>
                      <img key={i} src={b.image_url || b.image.url + '?w=200&q=80&fm=webp'} alt={`${b.name}${t('match.icon')}`} />
                      <p>{b.name}</p>
                    </div>
                  ))}
                  {node.blue.length === 0 && (
                    <img src={defaultUserIcon + '?w=200&q=80&fm=webp'} alt="" />
                  )}
                </div>
              </div>

              <div>
                <p className={Styles.result__match_title}>{node.title}</p>
                <div className={Styles.result__number_wrap}>
                  <p className={Styles.result__number}>
                    <strong>{node.blue_point}</strong>
                    {node.blue_examination !== null && <small>({node.blue_examination})</small>}
                  </p>
                  <p className={Styles.result__vs}>VS</p>
                  <p className={Styles.result__number}>
                    <strong>{node.red_point}</strong>
                    {node.red_examination !== null && <small>({node.red_examination})</small>}
                  </p>
                </div>
                <p className={Styles.result__btn}>
                  <Link to={`/match/${contentPathHelper(node.matchId)}/`}>{t('match.detail')}</Link>
                </p>
              </div>

              {/*赤アーティストアイコン*/}
              <div className={classNames(node.winner[0] === '赤' && Styles.result__winner)}>
                <div className={Styles.result__artist}>
                  {node.red.length > 0 && node.red.map((r, i) => (
                    <div>
                      <img key={i} src={r.image_url || r.image.url + '?w=200&q=80&fm=webp'} alt={`${r.name}${t('match.icon')}`} />
                      <p>{r.name}</p>
                    </div>
                  ))}
                  {node.red.length === 0 && (
                    <img src={defaultUserIcon + '?w=200&q=80&fm=webp'} alt="" />
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );

  return (
    <Layout location={location}>
      <Seo title={t('match.seo.title')} description={t('match.seo.description')} />
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
          {
            title: t('menu.match'),
          },
        ]}
        currentPage={t('menu.result')}
      />
      <BackGround>
        <section className={Styles.result}>
          <div className={Styles.result__wrap}>
            <div className={CommonStyles.container}>
              <h1>{t('menu.result')}</h1>
              <div className={Styles.result__tab}>
                <Link to="/schedule/">
                  <p className={Styles.result__tab_grey}>{t('match.schedule')}</p>
                </Link>
                <p className={Styles.result__tab_color}>
                  {t('match.result')}
                  <span className={Styles.result__triangle} />
                </p>
              </div>
              <div className={Styles.result__year}>
                {years.reverse().map((year) => (
                  <Link to={`/match/year/${contentPathHelper(year)}/`} className={`/match/year/${year}` === `/match/year/${currentYear}` && Styles.result__year__current}>
                    {year}
                  </Link>
                ))}
              </div>
              {nodes.length > 0 ? (
                location.pathname === '/match/' ? nodes.filter((node) => dayjs(node.date).format('YYYY') === currentYear).map((node) => matchComponent(node)) : nodes.map((node) => matchComponent(node))
              ) : (
                <p className={Styles.result__not_content}>{t('match.nodata')}</p>
              )}
            </div>
          </div>
          {/* pagination */}
          <div>
            {isPageNation && <Pagination currentPageNum={currentPageNum} pagination={pagination} prevPagePath={prevPagePath} nextPagePath={nextPagePath} />}
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export default Result;
